import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import AuthHelper from '../utils/authHelper';
import { ActiveSession } from '../models/activeSession';
import { LocalManager } from '../utils/localManager';

@Injectable({
    providedIn: 'root',
})
export class PrincipalAuthService {
    private router = inject(Router);

    private activeSession?: ActiveSession | undefined;

    constructor() {
        const existingAccessToken = AuthHelper.getSavedAccessToken();
        if (existingAccessToken) {
            this.login(existingAccessToken);
        }
    }

    isLoggedIn(): boolean {
        return !!this.getActiveSession()?.accessToken;
    }

    getActiveSession(): ActiveSession | undefined {
        return this.activeSession;
    }

    login(accessToken: string): void {
        this.activeSession = {
            accessToken,
        };
        this.saveActiveSession();
    }

    logout(): void {
        this.activeSession = undefined;
        LocalManager.clearActiveSession();
        void this.router.navigate(['auth', 'login']);
    }

    private saveActiveSession(): void {
        if (this.activeSession) {
            LocalManager.setActiveSession(this.activeSession);
        } else {
            LocalManager.clearActiveSession();
        }
    }
}
